import React from 'react';
import { QHeading, QStack } from '@qualio/ui-components';
import UserInfo from '../../../components/FormComponents/UserInfo';
import { GeneralHeader } from './__displayStrings__';

type GeneralSectionProps = {
  inviteIsPending: boolean;
};

const GeneralSection = ({ inviteIsPending }: GeneralSectionProps) => {
  return (
    <QStack direction={['column']}>
      <QHeading fontWeight="bold" size="md">
        {GeneralHeader}
      </QHeading>
      <UserInfo inviteIsPending={inviteIsPending} />
    </QStack>
  );
};

export default GeneralSection;
