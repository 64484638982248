import { MTBEUserCompanyEntity, MTBEUserEntity } from '../types';
import { MedtechManageablePermissions, MedtechManageablePermissionsArray } from './validation/medtechPermissions';
import { UserV2Type } from './validation/user';

const blankMedtechPermissions = MedtechManageablePermissionsArray.reduce((acc, permission) => {
  acc[permission] = false;
  return acc;
}, {} as UserV2Type['medtechPermissions']);

/**
 * Returns a medtechPermissions object with all permissions set to false
 */
export function GetBlankDefaultMedtechPermissions(): Record<MedtechManageablePermissions[number], boolean> {
  return { ...blankMedtechPermissions };
}

/**
 * Iterates over the array of userCompany permissions and sets the corresponding
 * attribute on the medtechPermissions object to true
 */
export function BuildMedtechPermissions(
  permissions: MTBEUserCompanyEntity['permissions'],
): UserV2Type['medtechPermissions'] {
  return permissions.reduce((aggregate, permission) => {
    const isManageablePermission = permission in aggregate;

    if (!isManageablePermission) {
      return aggregate;
    }

    return {
      ...aggregate,
      [permission]: true,
    };
  }, GetBlankDefaultMedtechPermissions());
}

/**
 * Builds a UserV2 object from a MTBEUserEntity and a MTBEUserCompanyEntity
 */
export const buildUserManagementUser = (
  medtechUser: MTBEUserEntity,
  userCompany: MTBEUserCompanyEntity,
): UserV2Type => {
  const { id, full_name: fullName, email, staff, created_time: createdTime, tz } = medtechUser;

  const { invite_status: inviteStatus, active, groups = [], usergroups } = userCompany;

  const [role] = usergroups;
  const isAdmin = Boolean(userCompany.is_admin);

  return {
    id,
    email,
    fullName,
    staff,
    createdTime,
    inviteStatus,
    active,
    groups,
    role,
    tz,
    medtechPermissions: BuildMedtechPermissions(userCompany.permissions),
    isAdmin,
  };
};
