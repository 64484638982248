import React from 'react';
import { Field, FieldProps } from 'formik';
import { QBox, QCheckbox, QFormControl, QInput, QLink, QSelect, QSelectItem, QStack } from '@qualio/ui-components';
import {
  FullNameLabel,
  EmailAddressLabel,
  UserRoleLabel,
  UserRoleInfoLinkText,
  AdminAccessLabel,
  BillingAccessLabel,
} from './__displayStrings__/index';
import { HelperTextSuppliers } from '../../../__displayStrings__/helperText';
import { UserRoleInfoLink } from '../../../constants';
import { UsergroupsList } from '../../../utils/umfe.enums';
import { MedtechRole } from '../../../types/medtechRole.types';

/**
 * Putting this type definition here as any From consuming this component will
 * need to use or extend these types in the Formik initial values
 */
export type UserInfoFormFields = {
  fullName: string;
  email: string;
  role: MedtechRole;
  adminAccess: boolean;
  billingAccess: boolean;
};

// Convert UsergroupsList into a select box options
const RoleSelectOptions = Object.values(UsergroupsList).map(({ value, label }) => ({ value, label }));

type UserInfoProps = {
  inviteIsPending: boolean;
};

const UserInfo = ({ inviteIsPending }: UserInfoProps) => {
  return (
    <QStack direction={['column']}>
      <QBox pb="2">
        <QFormControl label={FullNameLabel} size="sm">
          <Field name="fullName">
            {({ field, meta }: FieldProps) => {
              const { value, onChange, onBlur } = field;
              const { touched, error } = meta;
              const hasError = Boolean(touched && error);

              return (
                <QInput
                  data-cy="name-input"
                  id="fullName"
                  type="name"
                  placeholder={FullNameLabel}
                  aria-label="fullName"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={hasError}
                  isReadOnly={inviteIsPending}
                />
              );
            }}
          </Field>
        </QFormControl>
      </QBox>
      <QBox pb="2">
        <QFormControl label={EmailAddressLabel} size="sm">
          <Field name="email">
            {({ field, meta }: FieldProps) => {
              const { value, onChange, onBlur } = field;
              const { touched, error } = meta;
              const hasError = Boolean(touched && error);

              return (
                <QInput
                  data-cy="email-input"
                  id="email"
                  type="email"
                  placeholder={EmailAddressLabel}
                  aria-label="email"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={hasError}
                  isReadOnly={inviteIsPending}
                />
              );
            }}
          </Field>
        </QFormControl>
      </QBox>
      <QBox pb="2">
        <QFormControl data-testid="roleSelectFormControl" label={UserRoleLabel} size="sm">
          <Field name="role">
            {({ field, form }: FieldProps) => {
              const { value, name: fieldName } = field;
              const { setFieldValue } = form;
              const onChange = ({ value: val }: QSelectItem<string, string>) => {
                setFieldValue(fieldName, val);
              };
              return (
                <QSelect
                  aria-label="role"
                  options={RoleSelectOptions}
                  value={value}
                  onChange={(item) => item && onChange(item)}
                />
              );
            }}
          </Field>
          <QBox fontSize="14px" mt={2}>
            <QLink isExternal href={UserRoleInfoLink}>
              {UserRoleInfoLinkText}
            </QLink>
          </QBox>
        </QFormControl>
      </QBox>
      <QBox pb="2">
        <QFormControl size="sm" helper={HelperTextSuppliers}>
          <Field name="adminAccess">
            {({ field, form }: FieldProps) => {
              const { value, name: fieldName } = field;
              const { setFieldValue } = form;

              return (
                <QCheckbox
                  data-cy="admin-access-checkbox"
                  aria-label="admin access"
                  isChecked={value}
                  onChange={(event) => setFieldValue(fieldName, event.target.checked)}
                >
                  {AdminAccessLabel}
                </QCheckbox>
              );
            }}
          </Field>
        </QFormControl>
      </QBox>
      <QBox pb="2">
        <QFormControl size="sm">
          <Field name="billingAccess">
            {({ field, form }: FieldProps) => {
              const { value, name: fieldName } = field;
              const { setFieldValue } = form;

              return (
                <QCheckbox
                  data-cy="billing-access-checkbox"
                  aria-label="billing access"
                  isChecked={value}
                  onChange={(event) => setFieldValue(fieldName, event.target.checked)}
                >
                  {BillingAccessLabel}
                </QCheckbox>
              );
            }}
          </Field>
        </QFormControl>
      </QBox>
    </QStack>
  );
};

export default UserInfo;
