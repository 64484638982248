import { medtechClient } from '../services/medtechAxiosClient';
import {
  AddUsertoGroupPayload,
  ApiListResponse,
  ApiObjectResponse,
  CompanyInfo,
  CreateGroupPayload,
  CreateUserRequestBody,
  Group,
  InviteUserRequest,
  MTBECompanyEntity,
  MtbeCompanyUsersItem,
  MTBEDeleteUserRequestBody,
  MTBEUpdateUserDTO,
  MTBEUserEntity,
  RemoveGroupFromUserPayload,
  UpdateGroupPayload,
  UpdateManyByIdRequestBody,
  UpdateUserByIdRequestBody,
  User,
  UserAssociations,
} from '../types';
import { FeaturePayload, FeaturesResponse } from '../types/features.types';
import { MedtechRole } from '../types/medtechRole.types';
import { buildUserManagementUser } from '../utils/medtechUser';
import { validateIsFormerUserResponseData } from '../utils/validation/medtech-api';
import { CanAccessBilling } from '../utils/validation/medtechPermissions';
import { MTBEUserType, validateMTBEUser } from '../utils/validation/mtbe-user';
import {
  MedtechPermissionByRole,
  MedtechPermissionsByRoles,
  MedtechPermissionsByRolesMap,
  validateMedtechPermissions,
} from '../utils/validation/permissions';
import { UserV2Type, validateUserV2 } from '../utils/validation/user';
import { ManageableUsergroupPermission, UsergroupPermissionsByRoleResponse } from '../views/admin/Permissions/types';

/**
 * Gets medtech permissions based on the domain from MTBE
 * Used internally by other methods to avoid having components pass in specific domains
 */
const getMedtechDefaultPermissions = async <T>(companyId: number) => {
  const { data: permissions } = await medtechClient.get<T>(`/v2/${companyId}/permissions`);
  return permissions;
};

/**
 * Returns map of user roles to default QE permissions from MTBE
 */
export const getMedtechPermissions = async (companyId: number): Promise<MedtechPermissionsByRolesMap> => {
  const defaultPermissions = await getMedtechDefaultPermissions<MedtechPermissionsByRoles>(companyId);
  const defaultPermissionsValidated = validateMedtechPermissions(defaultPermissions);
  const permissionsMap = defaultPermissionsValidated.reduce(
    (acc: MedtechPermissionsByRolesMap, permissionsByRole: MedtechPermissionByRole) => {
      const { userGroup, permissions } = permissionsByRole;
      return {
        ...acc,
        [userGroup]: permissions.map(({ permission_id }) => permission_id),
      };
    },
    {} as MedtechPermissionsByRolesMap,
  );
  return permissionsMap;
};

export const isFormerUserCompany = async (companyId: number, email: string): Promise<boolean> => {
  const response = await medtechClient.get<{ former: number }>(`/companies/${companyId}/former`, {
    params: {
      email,
    },
  });
  const validatedData = validateIsFormerUserResponseData(response.data);

  return Boolean(validatedData.former);
};

export const deleteGroup = (groupId: number): Promise<unknown> => {
  return medtechClient.delete(`/groups/${groupId}`);
};

export const createGroup = (payload: CreateGroupPayload): Promise<unknown> => {
  return medtechClient.post('/groups', payload);
};

export const patchGroup = (groupId: number, payload: UpdateGroupPayload): Promise<ApiObjectResponse<Group>> => {
  return medtechClient.patch(`/groups/${groupId}`, payload);
};

export const getGroups = (companyId: number): Promise<ApiListResponse<Group>> => {
  return medtechClient.get(`/${companyId}/groups`);
};

export const addUsersToGroup = (payload: AddUsertoGroupPayload): Promise<ApiObjectResponse<Group>> => {
  return medtechClient.post('/groups/users/', payload);
};

export const removeUsersfromGroup = (data: RemoveGroupFromUserPayload): Promise<void> => {
  return medtechClient.delete(
    `/groups/users/?force=${data.forceRemove === true}&groups=${data.groups.join(',')}&users=${data.users.join(',')}`,
  );
};

export const getCompanyAdminSettings = (companyId: number) => {
  return medtechClient.get<{ ai_enabled: boolean; allow_ghost: boolean }>(`/company/${companyId}/admin_settings`);
};

export const patchCompanyAdminSettings = (
  companyId: number,
  settings: { ai_enabled?: boolean; allow_ghost?: boolean },
): Promise<unknown> => {
  return medtechClient.patch(`/company/${companyId}/admin_settings`, settings);
};

export const getCompanyFeatures = (companyId: number): Promise<FeaturesResponse> => {
  return medtechClient.get(`/company/${companyId}/features`);
};

export const patchCompanyFeatures = (companyId: number, payload: FeaturePayload): Promise<unknown> => {
  return medtechClient.patch(`/company/${companyId}/features`, payload);
};

export const fetchUsergroupPermissions = (companyId: number) => {
  return medtechClient
    .get<UsergroupPermissionsByRoleResponse>(`/v2/${companyId}/usergroup_permissions`)
    .then(({ data }) => data);
};

export const updateUsergroupPermission = async (
  companyId: number,
  usergroup: MedtechRole,
  permission: ManageableUsergroupPermission,
  enabled: boolean,
) => {
  await medtechClient.put<void>(`/v2/${companyId}/usergroup_permissions/${usergroup}`, {
    enabled,
    permission,
  });
};

export const updateUser = async (
  companyId: number,
  userId: number,
  payload: UpdateUserByIdRequestBody,
): Promise<MTBEUserType> => {
  const medtechPayload = { ...payload, company_id: companyId };
  const { data } = await medtechClient.patch(`/users/${userId}`, medtechPayload);

  return validateMTBEUser(data);
};

const buildMTBEPermissions = (medtechPermissions: any): MTBEUpdateUserDTO['permissions'] => {
  return Object.keys(medtechPermissions).map((permission) => ({
    [permission]: medtechPermissions[permission],
  }));
};

export const updateUserV2 = async (companyId: number, user: UserV2Type): Promise<MTBEUserType> => {
  const medtechPayload: MTBEUpdateUserDTO = {
    company_id: companyId,
    role: user.role,
    permissions: buildMTBEPermissions(user.medtechPermissions),
    is_admin: user.isAdmin,
  };
  // Only add full name and email to request payload if not a pending user
  if (user.inviteStatus !== 'pending') {
    medtechPayload.full_name = user.fullName;
    medtechPayload.email = user.email;
  }
  const { data } = await medtechClient.patch(`/users/${user.id}`, medtechPayload);
  return validateMTBEUser(data);
};

export const inviteUserInMedtech = (
  companyId: number,
  inviteUserRequest: InviteUserRequest,
): Promise<ApiObjectResponse<User>> => {
  const payload: CreateUserRequestBody = {
    email: inviteUserRequest.email,
    role: inviteUserRequest.role,
    is_admin: inviteUserRequest.isAdmin,
    is_billing: inviteUserRequest.isBilling,
  };

  return medtechClient.post(`/companies/${companyId}/invite`, payload);
};

export const updateUsers = (companyId: number, payload: UpdateManyByIdRequestBody): Promise<ApiListResponse<User>> => {
  return medtechClient.patch(`/v2/${companyId}/users/roles`, { user_updates: payload });
};

export const getUserAssociations = (
  userId: number,
  companyId: number,
): Promise<ApiObjectResponse<UserAssociations>> => {
  return medtechClient.get(`/v2/${companyId}/users/${userId}/associations`);
};

function generateUserAccess(isAdmin: boolean | undefined, userCompany: MtbeCompanyUsersItem): string[] {
  const access: string[] = [];
  if (userCompany.permissions.includes(CanAccessBilling)) {
    access.push('Billing');
  }
  if (isAdmin) {
    access.push('Admin');
  }
  return access;
}

export const mapMtbeUserToUser = (user: MtbeCompanyUsersItem): User => {
  return {
    id: user.id,
    email: user.email,
    invite_status: user.invite_status,
    full_name: user.full_name,
    role: user.usergroups[0] as MedtechRole,
    groups: user.groups,
    permissions: user.permissions.map((permission) => ({ [permission]: true })),
    access: generateUserAccess(user.is_admin, user),
    isAdmin: user.is_admin ?? false,
  };
};

export const getUsersByCompanyId = async (companyId: number): Promise<{ data: User[] }> => {
  const res = await medtechClient.get(`/v2/${companyId}/users?include_admin=true&include_group_data=true`);
  const parsedData = MtbeCompanyUsersItem.array().parse(res.data);
  return { data: parsedData.map(mapMtbeUserToUser) };
};

const formatCompanyInfo = (companyInfo: MTBECompanyEntity): CompanyInfo => {
  return {
    id: companyInfo.id,
    name: companyInfo.name,
    createdTime: new Date(companyInfo.created_time),
    status: companyInfo.status,
    trialEndsAt: companyInfo.trial_ends_at ? new Date(companyInfo.trial_ends_at) : null,
    logo: companyInfo.logo,
    ssoEnabled: companyInfo.sso_enabled,
    ssoDomain: companyInfo.sso_domain,
    ssoTurnedOn: companyInfo.sso_turned_on,
    activeUsersLimit: companyInfo.active_users_limit,
    totalUsersLimit: companyInfo.total_users_limit,
    passwordsExpireIn: companyInfo.passwords_expire_in ? String(companyInfo.passwords_expire_in) : null,
    trainingCompletionPeriod: companyInfo.training_completion_period,
    pricingPlan: companyInfo?.pricing_plan,
    activeUsersCount: companyInfo?.active_users_count,
    totalUsersCount: companyInfo?.total_users_count,
  };
};

export const getCompanyFromMedtech = (companyId: number): Promise<CompanyInfo> => {
  return medtechClient
    .get<MTBECompanyEntity>(`/v2/${companyId}/company?count_users=true`)
    .then((res) => formatCompanyInfo(res.data));
};

const findCompany = (rawUser: MTBEUserEntity, companyId: number) => {
  const comp = rawUser.companies.find((userComp) => userComp.id === companyId);
  if (!comp) {
    throw new Error('Invalid company for this user!');
  }
  return comp;
};

export const getUserById = async (
  companyId: number,
  userId: number,
  includeDeactivated: boolean = false,
): Promise<UserV2Type> => {
  const url = includeDeactivated ? `/users/${userId}?include_deactivated=true` : `/users/${userId}`;
  const { data: rawUser } = await medtechClient.get<MTBEUserEntity>(url);

  const userCompany = findCompany(rawUser, companyId);
  const umUser = buildUserManagementUser(rawUser, userCompany);

  return validateUserV2(umUser);
};

export const cancelUserInvite = (companyId: number, email: string): Promise<void> => {
  return medtechClient.delete(`/companies/${companyId}/invite`, { params: { email } });
};

export const deleteUserInMedtech = (
  companyId: number,
  userId: number,
  body?: MTBEDeleteUserRequestBody,
): Promise<void> => {
  return medtechClient.delete(`/v2/${companyId}/users/${userId}`, {
    data: body,
    headers: { Accept: 'application/json' },
  });
};
