import { z } from 'zod';

// Need to make a library for shared type information
export const CanAccessBilling = 'can_access_billing';
export const CanViewQE = 'feature_enabled_quality_events';
export const CanCreateIssue = 'can_create_issue';
export const CanWorkOnIssue = 'can_work_on_issue';
export const CanManageQualityEventAttributes = 'can_manage_quality_event_attributes';
export const CanManageQualityEventWorkflow = 'can_manage_quality_event_workflow';
export const CanAccessSuppliers = 'feature_enabled_supplier_quality_management';
export const CanAccessDesignControls = 'feature_enabled_product_development';
export const CanAccessAPICapabilities = 'can_access_api';
export const CanReviewDocuments = 'can_review_doc';

export const MedtechManageablePermissionsSchema = z.enum([
  CanAccessBilling,
  CanViewQE,
  CanCreateIssue,
  CanWorkOnIssue,
  CanManageQualityEventWorkflow,
  CanAccessSuppliers,
  CanAccessDesignControls,
  CanAccessAPICapabilities,
]);

export type MedtechManageablePermissionsType = z.infer<typeof MedtechManageablePermissionsSchema>;

export const MedtechPermissionsSchema = z.object({
  [CanAccessBilling]: z.boolean().optional(),
  [CanViewQE]: z.boolean().optional(),
  [CanCreateIssue]: z.boolean().optional(),
  [CanWorkOnIssue]: z.boolean().optional(),
  [CanManageQualityEventWorkflow]: z.boolean().optional(),
  [CanAccessSuppliers]: z.boolean().optional(),
  [CanAccessDesignControls]: z.boolean().optional(),
  [CanAccessAPICapabilities]: z.boolean().optional(),
});

// Admin
export const CanInviteUsers = 'can_invite_users';
export const CanManageUsers = 'can_manage_users';

/**
 * This const is used specifically to store permissions from MTBE that should be enabled for Admin users
 */
export const AdminPermissions = [CanInviteUsers, CanManageUsers];

export const MedtechManageablePermissionsArray = [
  CanAccessBilling,
  CanViewQE,
  CanCreateIssue,
  CanWorkOnIssue,
  CanManageQualityEventWorkflow,
  CanAccessSuppliers,
  CanAccessDesignControls,
  CanAccessAPICapabilities,
] as const;

export type MedtechManageablePermissions = (typeof MedtechManageablePermissionsArray)[number];
