import { z } from 'zod';
import { InviteStatusMap } from '../../types';

export const MTBEUserCompanySchema = z
  .object({
    id: z.number(),
    active: z.boolean().nullable(),
    currently_selected: z.boolean(),
    invite_status: z.nativeEnum(InviteStatusMap),
    is_admin: z.boolean().optional(),
    name: z.string(),
    permissions: z.string().array(),
    pricing_plan: z.string().nullable(),
    usergroups: z.string().array(),
  })
  .passthrough();

export type MTBEUserCompanyType = z.infer<typeof MTBEUserCompanySchema>;
