import React from 'react';
import { QStack, useToastProvider, useCurrentUser as useQCurrentUser } from '@qualio/ui-components';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router';
import { UserInfoFormFields } from '../../components/FormComponents/UserInfo';
import { QualityEventsPermissionsFormFields } from '../../components/FormComponents/QualityEventsPermissions';
import { SuppliersPermissionsFormFields } from '../../components/FormComponents/SuppliersPermissions';
import { CompanyInfo } from '../../types';
import {
  hasBillingPermissionV2,
  isInvitePendingV2,
  isUserChangingSelf,
  isValidFullName,
} from '../../utils/users.utils';
import { validateEmail } from '../../utils/validation/email';
import { UpdateUserFailedText, UpdateUserSuccessText } from './__displayStrings__/index';
import { createErrorToast, createSuccessToast } from '../../utils/toast.utils';
import { UserV2Type } from '../../utils/validation/user';
import {
  CanAccessBilling,
  CanCreateIssue,
  CanManageQualityEventWorkflow,
  CanViewQE,
  CanWorkOnIssue,
  CanAccessSuppliers,
  CanAccessDesignControls,
  CanAccessAPICapabilities,
} from '../../utils/validation/medtechPermissions';
import EditUserForm from './EditUserForm';
import { canManagePermissions } from '../../utils/company';
import { MedtechPermissionsByRolesMap } from '../../utils/validation/permissions';
import { isQEPermissionsChanged as isQEPermissionsChangedFn } from '../../utils/permissions.utils';
import { useUmAnalytics } from '../../analytics';
import { ErrorCodes, getErrorMessageOrDefault, getErrorResponseMessageOrDefault } from '../../errors/um-errors';
import {
  ActiveUsersLimitReachedErrorMessage,
  MinimumRequiredAdminsErrorMessage,
  MinimumRequiredQualityUsersErrorMessage,
} from '../../errors/__displayStrings__';
import redirect from '../../utils/redirect';
import { DesignControlsPermissionsFormFields } from '../../components/FormComponents/DesignControlsPermissions';
import { APICapabilitiesPermissionsFormFields } from '../../components/FormComponents/APICapabilitiesPermissions';
import { updateUserV2 } from '../../api/medtech-api';

type EditUserContentProps = {
  user: UserV2Type;
  company: CompanyInfo;
  defaultPermissions: MedtechPermissionsByRolesMap;
};

type FormValues = UserInfoFormFields &
  QualityEventsPermissionsFormFields &
  SuppliersPermissionsFormFields &
  APICapabilitiesPermissionsFormFields &
  DesignControlsPermissionsFormFields;

const EditUserContent = ({ user, company, defaultPermissions }: EditUserContentProps): React.ReactElement => {
  const { userId: currentUserId } = useQCurrentUser();
  const { id: targetUserId, fullName, email, role, isAdmin } = user;
  const { id: companyId } = company;

  const navigate = useNavigate();
  const analytics = useUmAnalytics();
  const { showToast } = useToastProvider();
  const inviteIsPending = isInvitePendingV2(user);
  const initialValues: FormValues = {
    fullName,
    email,
    role,
    adminAccess: isAdmin,
    billingAccess: hasBillingPermissionV2(user),
    canViewQE: Boolean(user.medtechPermissions[CanViewQE]),
    canCreateQE: Boolean(user.medtechPermissions[CanCreateIssue]),
    canEditQE: Boolean(user.medtechPermissions[CanWorkOnIssue]),
    canManageQE: Boolean(user.medtechPermissions[CanManageQualityEventWorkflow]),
    canAccessSuppliers: Boolean(user.medtechPermissions[CanAccessSuppliers]),
    canAccessDesignControls: Boolean(user.medtechPermissions[CanAccessDesignControls]),
    canAccessAPICapabilities: Boolean(user.medtechPermissions[CanAccessAPICapabilities]),
  };
  const onSubmit = async (values: FormValues) => {
    const {
      adminAccess,
      role: newRole,
      fullName: newFullName,
      email: newEmail,
      billingAccess,
      canViewQE,
      canCreateQE,
      canEditQE,
      canManageQE,
      canAccessSuppliers,
      canAccessDesignControls,
      canAccessAPICapabilities,
    } = values;

    const updatedUser: UserV2Type = {
      ...user,
      isAdmin: adminAccess,
      role: newRole,
      medtechPermissions: {
        [CanAccessBilling]: billingAccess,
        [CanViewQE]: canViewQE,
        [CanCreateIssue]: canCreateQE,
        [CanWorkOnIssue]: canEditQE,
        [CanManageQualityEventWorkflow]: canManageQE,
        [CanAccessSuppliers]: canAccessSuppliers,
        [CanAccessAPICapabilities]: canAccessAPICapabilities,
        [CanAccessDesignControls]: canAccessDesignControls,
      },
      fullName: newFullName,
      email: newEmail,
    };

    const isQEPermissionsChanged = isQEPermissionsChangedFn(user.medtechPermissions, updatedUser.medtechPermissions);

    try {
      await updateUserV2(companyId, updatedUser);

      if (!updatedUser.isAdmin && isUserChangingSelf(currentUserId, user.id)) {
        redirect();
      }

      showToast(createSuccessToast(UpdateUserSuccessText));

      if (isQEPermissionsChanged) {
        analytics.editUserQEPermissionsSuccess({ targetUserId });
      }

      navigate('/admin/users');
    } catch (error: unknown) {
      const errorMessageMap = {
        [ErrorCodes.MINIMUM_ADMINS_REQUIRED]: MinimumRequiredAdminsErrorMessage,
        [ErrorCodes.MINIMUM_QM_USER_REQUIRED]: MinimumRequiredQualityUsersErrorMessage,
        [ErrorCodes.INVALID_USER_UPDATE]: getErrorResponseMessageOrDefault(error, UpdateUserFailedText),
        [ErrorCodes.ACTIVE_USERS_LIMIT_REACHED]: ActiveUsersLimitReachedErrorMessage,
      };

      const errorMessage = getErrorMessageOrDefault(error, errorMessageMap, UpdateUserFailedText);

      showToast(createErrorToast(errorMessage));
      if (isQEPermissionsChanged) {
        analytics.editUserQEPermissionsFailed({ targetUserId });
      }
    }
  };

  const formValidation = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (inviteIsPending) {
      return errors;
    }

    if (!isValidFullName(values.fullName)) {
      errors.fullName = 'Invalid Full Name';
    }

    if (!validateEmail(values.email)) {
      errors.email = 'Invalid Email';
    }

    return errors;
  };

  const showQESection = canManagePermissions(company);

  return (
    <QStack direction={['column']} marginTop="1.5">
      <Formik initialValues={initialValues} validate={formValidation} onSubmit={onSubmit}>
        {({ dirty, isValid, isSubmitting, values }) => {
          // save button should be disabled unless the form has changed and is valid
          const saveDisabled = !dirty || !isValid;
          const { role: currentRole } = values;

          return (
            <Form>
              <QStack direction={['column']}>
                <EditUserForm
                  inviteIsPending={inviteIsPending}
                  showQESection={showQESection}
                  defaultPermissions={defaultPermissions}
                  userRole={currentRole}
                  isDisabled={saveDisabled}
                  isLoading={isSubmitting}
                  onCancel={() => navigate('/admin/users')}
                />
              </QStack>
            </Form>
          );
        }}
      </Formik>
    </QStack>
  );
};

export default EditUserContent;
