import { ChangeRequestOverride, DocumentOverride, EventTemplateOverride } from '../../types';

type DeleteUserPropertyKeys =
  | 'new_owner'
  | 'new_approver'
  | 'new_reviewer'
  | 'new_issue_owner'
  | 'new_task_owner'
  | 'new_change_request_owner'
  | 'new_event_template_owner';

const mapDataToMTBEDocument = (array: DocumentOverride[]) =>
  array.map(({ documentId, userId }) => ({
    document_id: documentId,
    user_id: userId,
  }));

const mapDataToMTBEChangeRequest = (array: ChangeRequestOverride[]) =>
  array.map(({ changeRequestId, userId }) => ({
    change_request_id: changeRequestId,
    user_id: userId,
  }));

const mapDatatoMTBEEventTemplate = (overrideArray: EventTemplateOverride[]) => {
  return overrideArray.map(({ eventTemplateId, userId }) => ({
    id: eventTemplateId,
    user_id: userId,
  }));
};

const mapperFunctionsByType = {
  new_owner: mapDataToMTBEDocument,
  new_approver: mapDataToMTBEDocument,
  new_reviewer: mapDataToMTBEDocument,
  new_issue_owner: mapDataToMTBEDocument,
  new_task_owner: mapDataToMTBEDocument,
  new_change_request_owner: mapDataToMTBEChangeRequest,
  new_event_template_owner: mapDatatoMTBEEventTemplate,
} as const;

/**
 * I believe this builds an object that looks like this:
 * @example (given "new_owner" as keyName)
 * ```typescript
 * {
 *   new_owner: 123,
 *   new_owner_override: [
 *    { document_id: 1, user_id: 123 },
 *    { document_id: 2, user_id: 90210 },
 *   ],
 * }
 * ```
 * So from my recollection/checking, the original intent here was that medtech BE would first
 * check for specific overrides (in this case "new_owner_override") and if it exists, it would use that
 * to reassign each individual document.  But as a fallback/shortcut, you could just supply the base key
 * "new_owner" and it would just reassign all documents to that user.
 *
 * User management FE however will only supply the array or overrides, even if they're all getting reassigned
 * to the same user, and I'm not sure all new overrides follow the same logic.  So if you're adding/or modifying
 * this behavior, you may have to check compatibility with both UM-FE and Medtech BE.
 */

export function buildMTBEDeleteUserPayload(
  keyName: DeleteUserPropertyKeys,
  overrides: DocumentOverride[] | ChangeRequestOverride[] | EventTemplateOverride[] = [],
) {
  if (!overrides.length) {
    return {};
  }
  const mapperFn = mapperFunctionsByType[keyName];

  return {
    [keyName]: overrides[0].userId,
    // Ts overload guarantees the override array is the correct type
    [`${keyName}_override`]: mapperFn(overrides as any),
  };
}
